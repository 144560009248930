<template>
  <v-dialog v-model="show" scrollable  persistent max-width="1000px">
    <v-card>
     <v-card-title>ADDITIONAL DETAIL</v-card-title>
        <v-divider></v-divider>
       <v-card-text style="height: 900px;">
          <v-form ref="formStep4" v-model="formStep4">
              
              <div class="cardHeader">Travel Details</div>
              <v-card flat class="pa-4 mt-2">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-radio-group
                      v-model="appData.fromHostNation"
                      label="Are you presently available at the host nation? *"
                      class="mt-0"
                      :rules="[rules.req]"
                    >
                      <v-radio label="Yes" value="Yes"></v-radio>
                      <v-radio label="No" value="No"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card>
              <div v-if="appData.fromHostNation == 'No'">
                <v-card flat outlined class="pa-4 mt-2 mb-2">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departPort"
                        hide-details
                        outlined
                        dense
                        label="Departure Port"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departDate"
                        hide-details
                        outlined
                        dense
                        type="date"
                        label="Departure Date"
                        :rules="[rules.req]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departTime"
                        hide-details
                        outlined
                        dense
                        type="time"
                        label="Departure Time"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.departFlight"
                        hide-details
                        outlined
                        dense
                        label="Departure Flight"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <v-card flat outlined class="pa-4 mt-2 mb-2">
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arrivePort"
                        hide-details
                        outlined
                        dense
                        label="Arriving Port"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arriveDate"
                        hide-details
                        outlined
                        dense
                        type="date"
                        label="Arriving Date"
                        :rules="[rules.req]"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arriveTime"
                        hide-details
                        outlined
                        dense
                        type="time"
                        label="Arriving Time"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.arriveFlight"
                        hide-details
                        outlined
                        dense
                        label="Arriving Flight"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="cardHeader">Visa Details</div>
                <v-card flat class="pa-4">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="appData.visaNo"
                        hide-details
                        outlined
                        dense
                        label="Visa Number"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="appData.visaValidTill"
                        hide-details
                        outlined
                        dense
                        type="date"
                        label="Visa Valid Till"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="cardHeader">Accommodation Details</div>
                <v-card flat class="pa-4">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        label="Line 1 *"
                        :rules="[rules.req]"
                        v-model="appData.accAddLine1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        hide-details
                        dense
                        label="Line 2"
                        v-model="appData.accAddLine2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.accCity"
                        hide-details
                        outlined
                        dense
                        label="City *"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.accState"
                        hide-details
                        outlined
                        dense
                        label="Parish/State/County *"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select
                        v-model="appData.accCountry"
                        hide-details
                        outlined
                        dense
                        label="Country *"
                        :items="countries"
                        :rules="[rules.req]"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="appData.accZipCode"
                        hide-details
                        outlined
                        dense
                        label="Postal/Zip Code  *"
                        :rules="[rules.req]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
              <div class="cardHeader">Covid 19 Assesment</div>
              <v-row class="ma-0">
                <v-col cols="12" md="12">
                  <v-radio-group
                    class="mt-0"
                    hide-details
                    v-model="appData.anyMedicalCondition"
                    :rules="[rules.req]"
                    label="Do you have any pre-existing medical condition?"
                  >
                    <v-radio label="Yes" value="Yes"></v-radio>
                    <v-radio label="No" value="No"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  v-if="appData.anyMedicalCondition == 'Yes'"
                >
                  <!-- <v-text-field
                    hide-details
                    outlined
                    dense
                    v-model="appData.medicalCondition"
                    label="Please specify medical condition *"
                    :rules="[rules.req]"
                    placeholder="Fever / Dry cough / Tiredness / Loss of taste or smell / Sore throat"
                  ></v-text-field> -->

 <v-select
                v-model="aclmc"
                hide-details
                outlined
                chips
                multiple
                 deletable-chips
                label="Please specify medical condition *"
                :items="['Fever', 'Dry cough', 'Tiredness','Loss of taste or smell','Sore throat', 'Asymptomatic']"
                :rules="[rules.reqArr]"
              >
              </v-select
            >


                </v-col>
              </v-row>
              <v-row class="ma-0">
                <!-- <v-col cols="12" md="12">
                  <v-radio-group
                    class="mt-0"
                    v-model="appData.isVaccinated"
                    hide-details
                    label="Have you been vaccinated? *"
                    :rules="[rules.req]"
                  >
                    <v-radio label="Yes" value="Yes"></v-radio>
                    <v-radio label="No" value="No"></v-radio>
                  </v-radio-group>
                </v-col> -->
              </v-row>
              <div v-if="appData.isVaccinated == 'Yes'">
                <v-row class="ma-0">
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      dense
                      label="Vaccination taken *"
                      hide-details
                      :items="vacineList"
                      v-model="appData.vaccineTaken"
                      :rules="[rules.req]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      dense
                      hide-details
                      label="Country of issue *"
                      :items="countries"
                      v-model="appData.vaccineCountry"
                      :rules="[rules.req]"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="ma-0">
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      dense
                      label="Date of first dose *"
                      type="date"
                      hide-details
                      v-model="appData.firstDose"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      outlined
                      dense
                      v-model="appData.secondDose"
                      label="Date of second dose"
                      type="date"
                      hide-details
                    ></v-text-field>
                  </v-col>

                  
                </v-row>
               
              </div>

              
            
            
            </v-form>
         </v-card-text>
          <v-divider></v-divider>
        <v-card-actions>
 <v-row style="margin:0px">
                
                 <v-btn
                    large
                    color="error"
                  @click="btncancel"
                    ><v-icon left> mdi-close-thick </v-icon>CLOSE</v-btn
                  >
                    <v-spacer></v-spacer>
                <v-btn
                    large
                   
                    :disabled="!formStep4"
                    color="success"
                  @click="save"
                    > <v-icon left> mdi-content-save </v-icon>SAVE</v-btn
                  >
                
               
             
                </v-row>

        </v-card-actions>
    </v-card>

 <v-snackbar
        top
        centered
        v-model="snackbar.display"
        :color="snackbar.color"
        >{{ snackbar.text }}</v-snackbar
      >
      <v-overlay :value="isLoading" style="z-index: 9999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

  </v-dialog>
</template>

<script>
//import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import nationality from "../../../data/nationality.json";
import country from "../../../data/country.json";
export default {
  components: {  },
  props: ["fData", "show"],

//  props: {
//     fData: {
//       type: Object,
//     },
//     show: {
//       type: Boolean,
//     },
//   },



  data: () => ({
     snackbar: { display: false, text: "", color: "primary" },
       isLoading: false,
    formStep4: false,
    dob_max: "",
    menu: false,
    stepLoading: false,
    countries: [],
    nationality: [],
    vacineList: [
      "Pfizer",
      "AstraZeneca",
      "Covaxin",
      "Covishield",
      "Moderna",
      "Sputnik V",
    ],
    aclmc:[],
    appData: {
      application_id:"",
      event_id: "",
      fromHostNation: "",
      departPort: "",
      departDate: "",
      departTime: "",
      departFlight: "",
      arrivePort: "",
      arriveDate: "",
      arriveTime: "",
      arriveFlight: "",
      visaNo: "",
      visaValidTill: "",
      accAddLine1: "",
      accAddLine2: "",
      accCity: "",
      accState: "",
      accCountry: "",
      accZipCode: "",
      anyMedicalCondition: "",
      medicalCondition: "",
      isVaccinated: "",
      vaccineTaken: "",
      vaccineCountry: "",
      firstDose: "",
      secondDose: "",
      boosterDose:"",
    },
    rules: {
      req: (val) => (val !== null && val !== "") || "Required!",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
      reqArr: (val) => val.length > 0 || "Required!",
    },
  }),

  watch: {
    "appData.fromHostNation"() {
      if(this.appData.fromHostNation=="Yes")
      {
     this.appData.departPort= "",
      this.appData.departDate= "",
      this.appData.departTime= "",
      this.appData.departFlight= "",
      this.appData.arrivePort= "",
      this.appData.arriveDate= "",
      this.appData.arriveTime= "",
      this.appData.arriveFlight= "",
      this.appData.visaNo= "",
      this.appData.visaValidTill= "",
      this.appData.accAddLine1= "",
      this.appData.accAddLine2= "",
      this.appData.accCity= "",
      this.appData.accState= "",
      this.appData.accCountry= "",
      this.appData.accZipCode= ""

      }
     

    },


    "appData.anyMedicalCondition"(val){

if(val=="No"){
     this.aclmc="";
//this.appData.medicalCondition= []
}

    },
    "appData.isVaccinated"(){
       if(this.appData.isVaccinated=="No")
      {
 this.appData.vaccineTaken= "",
      this.appData.vaccineCountry= "",
      this.appData.firstDose= "",
      this.appData.secondDose= "",
      this.appData.boosterDose= ""
      }
    }
  },

  mounted() {
    this.countries = country;
    this.nationality = nationality;
    console.log(this.fData.firstName);
    this.binddata();
  },
  methods: {
    savedob(date) {
      this.$refs.menu.save(date);
    },
     showSnackbar: function (scolor, stext) {
      this.snackbar.display = true;
      this.snackbar.text = stext;
      this.snackbar.color = scolor;
    },
    binddata: function () {
    //  console.log(this.fData.medicalCondition);
     // console.log(this.fData.medicalCondition.split(','));
      this.appData.application_id = this.fData.application_id;
      this.appData.event_id = this.fData.event_id;
      this.appData.fromHostNation = this.fData.fromHostNation;
      this.appData.departPort = this.fData.departPort;
      this.appData.departDate = this.fData.departDate;
      this.appData.departTime = this.fData.departTime;
      this.appData.departFlight = this.fData.departFlight;
      this.appData.arrivePort = this.fData.arrivePort;
      this.appData.arriveDate = this.fData.arriveDate;
      this.appData.arriveTime = this.fData.arriveTime;
      this.appData.arriveFlight = this.fData.arriveFlight;
      this.appData.visaNo = this.fData.visaNo;
      this.appData.visaValidTill = this.fData.visaValidTill;
      this.appData.accAddLine1 = this.fData.accAddLine1;
      this.appData.accAddLine2 = this.fData.accAddLine2;
      this.appData.accCity = this.fData.accCity;
      this.appData.accState = this.fData.accState;
      this.appData.accCountry = this.fData.accCountry;
      this.appData.accZipCode = this.fData.accZipCode;
      this.appData.anyMedicalCondition = this.fData.anyMedicalCondition;
      this.aclmc= this.fData.medicalCondition.split(",");
      this.appData.isVaccinated = this.fData.isVaccinated;
      this.appData.vaccineTaken = this.fData.vaccineTaken;
      this.appData.vaccineCountry = this.fData.vaccineCountry;
      this.appData.firstDose = this.fData.firstDose;
      this.appData.secondDose = this.fData.secondDose;
      this.appData.boosterDose = this.fData.boosterDose;
    console.log(this.aclmc);
    },

     save: async function () {
       this.isLoading = true;
       this.appData.medicalCondition = this.aclmc.toString();
      console.log(this.appData);
      if(this.appData.firstDose>this.appData.secondDose){
this.showSnackbar("error", "Second Dose Date must be later than the First Dose!");
this.isLoading = false;
return;
      }
      await this.$axios
        .put("Media/applicationAdditional", this.appData)
        .then((res) => {
          //console.log(res.data);
          if (res.data.status == "Success") {
            this.$emit("hideAdditionalDilog", {open:false,refresh:true});
           this.showSnackbar("success", "Updated Successfully!");
          } else if (res.data.status == "Failed") {
            if (res.data.message == "ALREADYEXISTS") {
              this.showSnackbar("error", "Applicant already registered!");
            } else {
              this.showSnackbar("error", "Something went wrong!");
            }
          } else {
            this.showSnackbar("error", "Something went wrong!");
          }
          this.isLoading = false;
        });
    },

 btncancel: function(){
    //this.show=false;
    this.$emit("hideAdditionalDilog", {open:false,refresh:true});
    this.binddata();
  },

 
  },
 
};
</script>

<style>
</style>