<template>
  <v-card flat class="mt-2 pt-3 mb-2 previewCard">
    <div
      style="
        width: 50px;
        height: 10px;
        background-color: #efefef;
        color: #efefef;
        margin: auto;
        margin-bottom: 10px;
      "
    ></div>
    <div class="text-center">
      <img style="width: 130px; border-radius: 7px" alt="" :src="logoPath" />

      <v-card
        flat
        width="120px"
        style="
          width: 120px;
          margin: auto;
          margin-top: 100px;
          height: 130px;
          border-radius: 0px;
        "
      >
        <img
          style="width: 120px"
          alt=""
          v-if="$store.state.imgHeadShot != ''"
          :src="$store.state.imgHeadShot"
        />
      </v-card>
    </div>
    <div
      style="
        background-color: #fff;
        border-radius: 0px !important;
        text-transform: uppercase;
        line-height: 1.1rem;
        padding: 8px 0px;
        font-weight: 600;
      "
    >
      <div class="text-center">{{ firstName }} {{ lastName }}</div>
      <div class="text-center">
        {{ mediaName }}
      </div>
       <div class="text-center">
        {{ designation }}
      </div>
      <div class="text-center">
        {{ zone }}
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["firstName", "lastName", "mediaName","designation", "zone"],
  data: () => ({
    logoPath: "",
  }),
  mounted() {
    this.logoPath =
      this.$apiPhotoPath +
      "/assets/" +
      this.$store.state.eventUrl +
      "/logo.jpg";
  },
};
</script>

<style scoped>
.previewCard {
  background-color: #ffffff;
  background-image: linear-gradient(
    140deg,
    #5c5c5c 0%,
    #aaaaaa 50%,
    #5c5c5c 85%
  );
  max-width: 250px;
  height: 520px;
  margin: auto;
  border-radius: 15px;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
</style>