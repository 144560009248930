<template>
  <v-dialog v-model="show" scrollable persistent max-width="1000px">
    <v-card>
      <v-card-title>JOB DETAIL</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 900px">
        <v-form ref="formStep3" v-model="formStep3">
          <div class="cardHeader">Job DetailsPKL</div>

          <v-card flat class="pa-4">
            <v-row>
              <v-col cols="12" md="12">
                <v-radio-group
                  v-model="appData.isPastEvent"
                  :rules="[rules.req]"
                  label="Have you covered any ICC event previously? *"
                >
                  <v-radio label="Yes" value="yes"></v-radio>
                  <v-radio label="None" value="no"></v-radio>
                  <v-radio
                    label="Others (Any other international cricket tour)"
                    value="others"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  label="Which events have you previously covered? *"
                  outlined
                  hide-details
                  multiple
                  chips
                  deletable-chips
                  :items="pastEvents"
                  v-model="appData.pastEvents"
                  item-text="pe_name"
                  item-value="pe_id"
                  v-if="appData.isPastEvent == 'yes'"
                  :rules="[rules.reqArr]"
                >
                </v-select>
                <v-text-field
                  v-model="appData.pastOthers"
                  hide-details
                  outlined
                  label="Enter other international cricket tour"
                  :rules="[rules.req]"
                  v-if="appData.isPastEvent == 'others'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  label="Please select your role below for the VIVO PRO KABADDI SEASON 8"
                  outlined
                  chips
                  deletable-chips
                  hide-details
                  :rules="[rules.req]"
                  v-model="appData.roleForEvent"
                  :items="mediaRole"
                  item-text="role_name"
                  item-value="role_id"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  label="Please select prefered city to collect accreditation card"
                  outlined
                  chips
                  deletable-chips
                  hide-details
                  :rules="[rules.req]"
                  v-model="appData.preferedCity"
                  :items="preferedCityList"
                  item-text="venue_name"
                  item-value="venue_id"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  label="Select the match you would like to cover in Cricket World Cup 2021"
                  outlined
                  chips
                  deletable-chips
                  hide-details
                  multiple
                  v-model="appData.selectedMatchs"
                  :rules="[rules.reqArr]"
                  :items="currentEvents"
                  item-disabled="md_flag"
                  item-text="match_name"
                  item-value="s_id"
                  @change="SelectMatch"
                  return-object
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn large class="pl-5 pr-5 ma-2" color="error" @click="btncancel"
          ><v-icon left> mdi-close-thick </v-icon>CLOSE</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          large
          class="pl-5 pr-5"
          color="success"
          :loading="stepLoading"
          :disabled="!formStep3"
          @click="save"
        >
          <v-icon left> mdi-content-save </v-icon>
          SAVE</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-snackbar
      top
      centered
      v-model="snackbar.display"
      :color="snackbar.color"
      >{{ snackbar.text }}</v-snackbar
    >
    <v-overlay :value="isLoading" style="z-index: 9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import nationality from "../../../data/nationality.json";
import country from "../../../data/country.json";
export default {
  components: {},
  props: ["fData", "show"],

  //  props: {
  //     fData: {
  //       type: Object,
  //     },
  //     show: {
  //       type: Boolean,
  //     },
  //   },

  data: () => ({
    snackbar: { display: false, text: "", color: "primary" },
    isLoading: false,
    formStep3: false,
    dob_max: "",
    menu: false,
    stepLoading: false,
    mediaRole: [],
    preferedCityList: [],
    currentEvents: [],
    pastEvents: [],
    appData: {
      application_id: "",
      event_id: "",
      isPastEvent: "",
      pastEvents: [],
      pastOthers: "",
      roleForEvent: "",
      preferedCity: "",
      selectedMatchs: [],
    },
    rules: {
      req: (val) => (val !== null && val !== "") || "Required!",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
      reqArr: (val) => val.length > 0 || "Required!",
    },
  }),

  watch: {
    "appData.isPastEvent"() {
      //  this.pastEvents = [];

      if (this.appData.isPastEvent == "no") {
        this.appData.pastEvents = [];
        this.appData.pastOthers = "";
      } else if (this.appData.isPastEvent == "others") {
        this.appData.pastEvents = [];
      } else {
        this.appData.pastOthers = "";
      }
    },
  },

  mounted() {
    this.binddata();
    this.countries = country;
    this.nationality = nationality;

    this.$axios
      .get("/media/pastevents")
      .then((res) => {
        this.pastEvents = res.data.result;
      })
      .catch();

    this.$axios
      .get("/media/mediarole/" + this.appData.event_id)
      .then((res) => {
        this.mediaRole = res.data.result;
      })
      .catch();

    this.$axios
      .get("/media/mediavenue/" + this.appData.event_id)
      .then((res) => {
        this.preferedCityList = res.data.result;
      })
      .catch();

    this.$axios
      .get("/media/matchschedule/" + this.appData.event_id)
      .then((res) => {
        this.currentEvents = res.data.result;
        console.log(this.currentEvents);
        console.log(res.data.result);
      })
      .catch();

    console.log(this.fData.firstName);
  },
  methods: {
    savedob(date) {
      this.$refs.menu.save(date);
    },

    SelectMatch(val) {
      console.log(val);
      //this.md_flag=1;
      for (let i = 0; i < this.currentEvents.length; i++) {
        this.currentEvents[i].md_flag = false;
      }
      val.forEach((el) => {
        for (let i = 0; i < this.currentEvents.length; i++) {
          if (el.match_date == this.currentEvents[i].match_date) {
            if (el.s_id != this.currentEvents[i].s_id) {
              this.currentEvents[i].md_flag = true;
            } else {
              this.currentEvents[i].md_flag = false;
            }
          }
        }
      });
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar.display = true;
      this.snackbar.text = stext;
      this.snackbar.color = scolor;
    },
    binddata: function () {
      this.appData.application_id = this.fData.application_id;
      this.appData.event_id = this.fData.event_id;
      this.appData.pastEvents = this.fData.pastEvents;
      this.appData.isPastEvent = this.fData.isPastEvent;
      this.appData.roleForEvent = this.fData.roleForEvent;
      this.appData.preferedCity = this.fData.preferedCity;
      this.appData.selectedMatchs = this.fData.selectedMatchs;
    },

    save: async function () {
      this.isLoading = true;
      console.log(this.appData);
      await this.$axios
        .put("Media/applicationJob", this.appData)
        .then((res) => {
          //console.log(res.data);
          if (res.data.status == "Success") {
            this.$emit("hideJobDilog", { open: false, refresh: true });
            this.showSnackbar("success", "Updated Successfully!");
          } else if (res.data.status == "Failed") {
            if (res.data.message == "ALREADYEXISTS") {
              this.showSnackbar("error", "Applicant already registered!");
            } else {
              this.showSnackbar("error", "Something went wrong!");
            }
          } else {
            this.showSnackbar("error", "Something went wrong!");
          }
          this.isLoading = false;
        });
    },

    btncancel: function () {
      //this.show=false;
      this.$emit("hideJobDilog", { open: false, refresh: false });
    },
  },
};
</script>

<style>
</style>