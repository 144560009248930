<template>
  <div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="error" @click="loadId"
        ><v-icon left>mdi-file</v-icon> File</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>
    <input
      style="display: none"
      ref="input"
      type="file"
      name="image"
       accept="image/*,application/pdf"
      @change="setIdImage"
    />
    <v-dialog
      v-model="idPhoto"
      scrollable
      persistent
      max-width="650px"
      transition="dialog-bottom-transition"
    >
      <v-card flat class="pa-5">
        <v-card-text>
          <div class="content">
            <section class="cropper-area">
              <div class="img-cropper">
                <VueCropper ref="cropper" :src="imgIdSrc" :key="imgIdSrc" />
              </div>
            </section>
          </div>
        </v-card-text>
        <v-card-actions class="pt-2" style="display: block">
          <div class="pt-3 text-center" style="display: block; width: 100%">
            <div
              style="
                border: 1px solid #e6e6e6;
                background-color: #fafafa;
                border-radius: 8px;
                padding: 3px 0px;
              "
            >
              <v-btn icon small @click="imageOpt(0)" class="mr-3">
                <v-icon>mdi-magnify-plus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(1)" class="mr-3">
                <v-icon>mdi-magnify-minus</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(2)" class="mr-3">
                <v-icon>mdi-flip-horizontal</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(3)" class="mr-3">
                <v-icon>mdi-rotate-right</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(4)" class="mr-3">
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(5)" class="mr-3">
                <v-icon>mdi-arrow-right-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(6)" class="mr-3">
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(7)" class="mr-3">
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn icon small @click="imageOpt(8)">
                <v-icon>mdi-lock-reset</v-icon>
              </v-btn>
            </div>
          </div>
          <div style="display: flex; width: 100%; margin-top: 15px">
            <v-btn color="error" outlined @click="close">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="closeDialog">Submit</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import pdfjs from "pdfjs-dist";
import { canvasToBlob } from "blob-util";

export default {
  components: { VueCropper },
  props: {
    upload: String,
    Applicationid: String,
    FirstName: String,
  },
  data: () => ({
    imgIdSrc: "",
    idPhoto: false,
    photoupload: {
      event_id: 0,
      application_id: 0,
      firstName: "",
      photo: "",
      photoIdFront: "",
      photoIDBack: "",
    },
  }),
  mounted() {},
  methods: {
    loadId: function () {
      this.$refs.input.click();
    },
    setIdImage: function (e) {
      const file = e.target.files[0];
      if (file.size > 2 * (1024 * 1024)) {
        alert("File too big (> 2MB)");
        return;
      }
      if (file.type === "application/pdf") {
        this.convertToImage(file);
      } else {
        if (file.type.indexOf("image/") === -1) {
          alert("Please select an image file");
          return;
        }
        if (typeof FileReader === "function") {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgIdSrc = event.target.result;
            this.idPhoto = true;
            // rebuild cropperjs with the updated source
            //this.$refs.cropper.replace(event.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      }
    },

    async convertToImage(file) {
      const pdfData = await this.readPdfFile(file);
      const imageBlob = await this.convertPdfToImage(pdfData);
      const imageSrc = URL.createObjectURL(imageBlob);
      this.imgIdSrc = imageSrc;
      this.idPhoto = true;
    },
    async readPdfFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    async convertPdfToImage(pdfData) {
      const pdf = await pdfjs.getDocument({ data: pdfData }).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement("canvas");
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: canvas.getContext("2d"), viewport })
        .promise;
      return canvasToBlob(canvas);
    },
    closeDialog: function () {
      this.$store.commit(
        "setIdImg",
        this.$refs.cropper
          .getCroppedCanvas({
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1)
      );

      if (this.upload == "server") {
        this.picpathbackup = this.$refs.cropper
          .getCroppedCanvas({
            // minWidth: 400,
            // minHeight: 350,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1);
        this.picpath = this.$refs.cropper
          .getCroppedCanvas({
            // minWidth: 400,
            // minHeight: 350,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1);
        this.photoupload.event_id = this.$store.state.eventInfo.EventId;
        this.photoupload.application_id = this.Applicationid;
        this.photoupload.firstName = this.FirstName;
        this.photoupload.photoIdFront = this.picpath.split(",")[1];
        axios
          .put(this.$apiPathNew + "/Media/UploadPic", this.photoupload, {
            headers: { Appkey: this.$aKey },
          })
          .then(() => {})
          .catch()
          .finally();
      }
      this.$store.commit("setIdOpen", false);
      this.idPhoto = false;
    },
    close: function () {
      this.$store.commit("setIdOpen", false);
      this.idPhoto = false;
    },
    imageOpt: function (bIndex) {
      switch (bIndex) {
        case 0:
          this.$refs.cropper.relativeZoom(0.2);
          break;
        case 1:
          this.$refs.cropper.relativeZoom(-0.2);
          break;
        case 2:
          // const dom = this.$refs.cropper;
          // let scale = dom.getAttribute("data-scale");
          // scale = scale ? -scale : -1;
          // this.$refs.cropper.scaleX(scale);
          // dom.setAttribute("data-scale", scale);
          break;
        case 3:
          this.$refs.cropper.rotate(90);
          break;
        case 4:
          this.$refs.cropper.move(-10, 0);
          break;
        case 5:
          this.$refs.cropper.move(10, 0);
          break;
        case 6:
          this.$refs.cropper.move(0, -10);
          break;
        case 7:
          this.$refs.cropper.move(0, 10);
          break;
        case 8:
          this.$refs.cropper.reset();
      }
    },
  },
};
</script>

<style></style>
