<template>
  <div>
    <ProfileLfw v-if="$store.state.eventInfo.TemplateID == 1"></ProfileLfw>
    <ProfilePkl v-else-if="$store.state.eventInfo.TemplateID == 2"></ProfilePkl>
    <ProfileIcc v-else-if="$store.state.eventInfo.TemplateID == 5"></ProfileIcc>
    <ProfilePklWI v-else-if="$store.state.eventInfo.TemplateID == 6"></ProfilePklWI>
    <ProfileIlt v-else-if="$store.state.eventInfo.TemplateID == 7"></ProfileIlt>
  </div>
</template>

<script>
import ProfilePkl from "../components/Template/PKL/ProfilePkl.vue";
import ProfileLfw from "../components/Template/LFW/ProfileLfw.vue";
import ProfileIcc from '../components/Template/ICC/ProfileICC.vue';
import ProfileIlt from '../components/Template/ILT/ProfileICC.vue';
import ProfilePklWI from "../components/Template/PKLWI/ProfilePklWI.vue"
export default {
  components: { ProfilePkl, ProfileLfw, ProfileIcc,ProfilePklWI,ProfileIlt },
};
</script>

<style>
</style>