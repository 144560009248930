<template>
  <v-container fluid>
    <v-alert
      type="info"
      dense
      text
      width="600px"
      class="mb-3"
      style="margin: auto"
    >
      {{ Appstatus }}
    </v-alert>
    <v-tabs color="primary" fixed-tabs v-model="tab">
      <v-tab>Personal Details</v-tab>
      <v-tab>Publication Details</v-tab>

      <!-- <v-tab>Additional Details</v-tab> -->
      <v-tab>Important Information</v-tab>
    </v-tabs>
    <v-row class="mt-1" v-if="pData">
      <v-col cols="12" md="8">
        <v-tabs-items v-model="tab" style="background-color: transparent">
          <v-tab-item>
            <v-card flat>
              <div class="divHead">
                Personal Details

                <v-btn
                  v-if="btnEdit == true"
                  tile
                  color="success"
                  style="float: right"
                  @click="btnEditPersonaldetatl"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  Edit
                </v-btn>
              </div>

              <div class="pa-3">
                <v-row>
                  <v-col cols="12" md="4">
                    <FieldValue Text="First Name" :Value="pData.firstName" />
                  </v-col>
                  <v-col cols="12" md="4">
                    <FieldValue Text="Last Name" :Value="pData.lastName" />
                  </v-col>
                   <v-col cols="12" md="4">
                    <FieldValue Text="Father/Guardian Name" :Value="pData.fatherName" />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue Text="Gender" :Value="pData.gender" />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue Text="DOB" :Value="pData.dob" />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue Text="Nationality" :Value="pData.nationality" />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="ID Proof Type"
                      :Value="pData.idProofType"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="ID Proof Number"
                      :Value="pData.idProofNumber"
                    />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue Text="Address line 1" :Value="pData.addLine1" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <FieldValue Text="Line 2" :Value="pData.addLine2" />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="3">
                    <FieldValue Text="Town/City" :Value="pData.city" />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue
                      Text="State"
                      :Value="pData.state"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue Text="Country" :Value="pData.country" />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue Text="Zip/Postal Code" :Value="pData.zipCode" />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="Mobile"
                      :Value="pData.ext + ' ' + pData.mobile"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <FieldValueLowercase
                      Text="Email"
                      style="text-transform: lowercase"
                      :Value="pData.email"
                    />
                  </v-col>
                </v-row>
                <!-- Comment due to Hockey FIH 2023 -->
                <!-- <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="12">
                    <FieldValue Text="Vaccinated" :Value="pData.isVaccinated" />
                  </v-col>
                </v-row> -->
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <div class="divHead">
                Publication Details

                <v-btn
                  v-if="btnEdit == true"
                  tile
                  color="success"
                  style="float: right"
                  @click="btnEditPublicationDetals"
                >
                  <v-icon left> mdi-pencil </v-icon>
                  Edit
                </v-btn>
              </div>

              <div class="pa-3">
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="Name of Media House"
                      :Value="pData.mediaName"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <FieldValueLowercase
                      Text="Publication Website"
                      :Value="pData.mwdiaWebsite"
                    />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="Media Category"
                      :Value="pData.mediaType_name"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="Type of Organisation"
                      :Value="pData.organisationType_name"
                    />
                  </v-col>
                  <v-divider class="mt-1 mb-2"></v-divider>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="Role for Tournament"
                      :Value="pData.role_name"
                    />
                  </v-col>
                  <v-col cols="12" md="6" v-if="pData.venue_type == 'CENTRAL'">
                    <FieldValue
                      Text="Prefered City for Card Pickup"
                      :Value="pData.venue_name"
                    />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="Address line 1"
                      :Value="pData.pAddLine1"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <FieldValue Text="Line 2" :Value="pData.pAddLine2" />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="3">
                    <FieldValue Text="Town/City" :Value="pData.pCity" />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue
                      Text="Parish/State/County"
                      :Value="pData.pState"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue Text="Country" :Value="pData.pCountry" />
                  </v-col>
                  <v-col cols="12" md="3">
                    <FieldValue
                      Text="Zip/Postal Code"
                      :Value="pData.pZipCode"
                    />
                  </v-col>
                </v-row>
                <div v-if="pData.organisationType_name!='Freelancer'">
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue Text="Editor Name" :Value="pData.authName" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <FieldValue Text="Role" :Value="pData.authRole" />
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-2"></v-divider>
                <v-row>
                  <v-col cols="12" md="6">
                    <FieldValue
                      Text="Mobile"
                      :Value="pData.authMobExt + ' ' + pData.authMobile"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <FieldValueLowercase
                      Text="Email"
                      :Value="pData.authEmail"
                    />
                  </v-col>
                </v-row>
                </div>
              </div>
            </v-card>
          </v-tab-item>





         <v-tab-item>
            <v-card flat>
              <div class="divHead">Important Information</div>
              <div class="pa-3">
                <v-row>
                  <v-col cols="12" md="12">
                    <ul>
                      <li>
                        Your accreditation card can <u><b>only</b></u> be collected by the named individual/s
                      </li>
                     
                      <li>
                        Accreditation centres opening dates and times will be advised upon the approval of your accreditation.
                      </li>
                      <li>
                        Approval of the Accreditation is at the discretion of the tournament. Only bonafide members of the <u><b>working media</b></u> are allowed in the press areas and these should not be considered <u><b>to watch matches as spectators.</b></u>
                      </li>
                      <li>
                        Videography and live capture of any match is <u><b>not permitted</b></u> inside any venue - this is restricted to the authorised Global Broadcast Partner.
                      </li>
                    </ul>

                    <v-card flat class="pa-4">
                      <ul>
                        <li>
                          <v-card flat class="mt-3">
                            <div class="cardHeader">Schedule</div>
                            <div class="pa-3">
                              <v-card flat class="mt-6">
                                <div class="text-center pa-2">
                                  <v-btn
                                    outlined
                                    color="primary"
                                    target="blank"
                                    @click="btnviewschedule"
                                    >View Schedule
                                    <v-icon right
                                      >mdi-book-search-outline</v-icon
                                    ></v-btn
                                  >
                                </div>
                              </v-card>
                            </div>
                          </v-card>
                        </li>
                      </ul>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="12" md="4">
                    <CardPreview
                      :firstName="pData.firstName"
                      :lastName="pData.lastName"
                      :mediaName="pData.mediaName"
                      :designation="pData.role_name"
                      :zone="pData.print_text"
                    ></CardPreview>
                  </v-col> -->
                </v-row>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat>
          <div class="divHead">Headshot Photo</div>
          <div class="text-center pa-2">
            <img
              width="130px"
              style="border: 1px solid #ccc; border-radius: 8px"
              alt=""
              v-if="$store.state.imgHeadShot != ''"
              :src="$store.state.imgHeadShot"
            />
          </div>

          <v-card flat>
            <Headshot
              upload="server"
              :Applicationid="this.pData.application_id.toString()"
              :FirstName="this.pData.firstName"
               v-if="btnEdit == true"
            ></Headshot>
          </v-card>
        </v-card>
        <v-card flat class="mt-3">
          <div class="divHead">ID Proof</div>
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-center pa-2">
                <img
                  :src="$store.state.imgId"
                  style="
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    max-height: 150px;
                    max-width: 100%;
                  "
                  alt=""
                />
              </div>
              <v-card flat>
               
                <IdCard
                  upload="server"
                  :Applicationid="pData.application_id.toString()"
                  :FirstName="pData.firstName"
                   v-if="btnEdit == true"
                ></IdCard>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-center pa-2"> <!--  v-if="pData.photoIDBack" -->
                <img
                  :src="$store.state.imgIdBack"
                  style="
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    max-height: 150px;
                    max-width: 100%;
                  "
                  alt=""
                />
              </div>

              <v-card flat>
               
                <IdCardBack
                  upload="server"
                  :Applicationid="pData.application_id.toString()"
                  :FirstName="pData.firstName"
                   v-if="btnEdit == true"
                ></IdCardBack>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-card flat>
          <div class="divHead">Editor's Letter</div>
          <div class="text-center pa-2 pt-1">
            <v-btn
              outlined
              color="primary"
              @click="btnvieweditor()"
              target="blank"
              >View Editor Letter
              <v-icon right>mdi-book-search-outline</v-icon></v-btn
            >
          </div>
          <v-row v-if="isfileupload == true">
            <v-col cols="12" md="9">
              <v-file-input
                label="Choose File *"
                ref="fileLetter"
                outlined
                show-size
                dense
                accept=".png, .jpeg, .jpg, .pdf"
                hint="File type should be in PDF/JPG/JPEG/PNG"
                persistent-hint
                :rules="[rules.req]"
                @change="selectFileLetter"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn color="primary" @click="btnupload">Upload</v-btn>
            </v-col>
          </v-row>

          <div class="text-center pa-2">
            <v-btn
              v-if="btnEdit == true"
              text
              outlined
              @click="btncheckupload"
              color="primary"
              >Change File <v-icon right>mdi-upload</v-icon></v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <div
        class="pt-10 mt-10 text-center"
        style="max-width: 400px; margin: auto"
      >
        Loading your data.
        <v-progress-linear
          class="mt-4"
          color="primary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </div>
    </div>

    <v-snackbar
      top
      centered
      v-model="snackbar.display"
      :color="snackbar.color"
      >{{ snackbar.text }}</v-snackbar
    >
    <v-overlay :value="isLoading" style="z-index: 9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog
      max-width="800"
      scrollable
      v-model="dialogpdf"
      :fullscreen="fullscreen1"
    >
      <v-card flat>
        <v-toolbar flat>
          <div style="font-weight: 600">Schedule</div>
          <v-spacer></v-spacer>
          <v-btn right icon color="red" @click="makefulldilog"
            ><v-icon>{{ maxiconname }}</v-icon></v-btn
          >
          <v-btn right icon color="red" @click="dialogpdf = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="height: 600px">
          <pdf :src="schedulepath" :key="schedulepath"> </pdf>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="600"
      scrollable
      v-model="dialogeditor"
      :fullscreen="fullscreen1"
    >
      <v-card flat>
        <v-toolbar flat>
          <div style="font-weight: 600">Editor letter</div>
          <v-spacer></v-spacer>
          <v-btn right icon color="red" @click="makefulldilog"
            ><v-icon>{{ maxiconname }}</v-icon></v-btn
          >
          <v-btn right icon color="red" @click="dialogeditor = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="height: 600px" v-if="this.pData.editorLetter!=null">
          <pdf
            v-if="
              this.pData.editorLetter.substring(
                this.pData.editorLetter.length - 3
              ) == 'pdf'
            "
            :src="this.$apiPhotoPath + '/' + pData.editorLetter"
            :key="this.$apiPhotoPath + '/' + pData.editorLetter"
          >
          </pdf>
          <v-img
            v-else
            :src="this.$apiPhotoPath + '/' + pData.editorLetter"
            :key="this.$apiPhotoPath + '/' + pData.editorLetter"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>

    <BasicDetails
      v-if="pData"
      :fData="pData"
      @hideBasicDilog="btnEditPersonaldetatlHide"
      :show="DilogBasic"
    ></BasicDetails>
    <PublicationDetails
      v-if="pData"
      :fData="pData"
      @hidePublicationDilog="btnEditPublicationdetailHide"
      :show="DilogPublication"
    ></PublicationDetails>
    <JobDetails
      v-if="pData"
      :fData="pData"
      @hideJobDilog="btnEditJobdetailHide"
      :show="DilogJob"
    ></JobDetails>
    <AdditionalDetails
      v-if="pData"
      :fData="pData"
      @hideAdditionalDilog="btnEditAdditionaldetailHide"
      :show="DilogAdditional"
    ></AdditionalDetails>
  </v-container>
</template>

<script>
//import axios from "axios";
import FieldValue from "../../FieldValue";
import FieldValueLowercase from "../../FieldValueLowercase";
//import CardPreview from "../../CardPreview";
import BasicDetails from "./BasicDetails";
import PublicationDetails from "./PublicationDetails.vue";
import JobDetails from "./JobDetails.vue";
import AdditionalDetails from "./AdditionalDetails.vue";
import Headshot from "../../HeadShot";
import IdCard from "../../IdCard";
import IdCardBack from "../../IdCardBack";
import pdf from "vue-pdf";

export default {
  components: {
    FieldValue,
    FieldValueLowercase,
    
    BasicDetails,
    PublicationDetails,
    JobDetails,
    AdditionalDetails,
    Headshot,
    IdCard,
    IdCardBack,
    pdf,
  },
  data: () => ({
    maxiconname: "mdi-fullscreen",
    schedulepath: "",
    fullscreen1: false,
    dialogpdf: false,
    dialogeditor: false,
    isfileupload: false,
    currentFile: "",
    minitData: "",
    tab: null,
    pData: "",
    Appstatus: "",
    DilogBasic: false,
    DilogPublication: false,
    DilogJob: false,
    DilogAdditional: false,
    btnEdit: false,
    snackbar: { display: false, text: "", color: "primary" },
    isLoading: false,
    rules: {
      req: (val) => (val !== null && val !== "") || "Required!",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
      reqArr: (val) => val.length > 0 || "Required!",
    },
  }),

  async mounted() {
    this.$store.commit("setPageTitle", "My Profile");
    this.$store.commit("setHeadshot", false);

    const mtemp = await this.$axios.get(
      "/media/onload/" +
        this.$store.state.eventUrl +
        "/" +
        this.$store.state.venueUrl
    );

    this.minitData = await mtemp.data.result;
    console.log("AllDAta");
    console.log(this.minitData);

    this.loadprofile();
    // this.pData.email=this.pData.email.toLowerCase();
  },
  methods: {
    loadHeadShot: function (mode, upload) {
      this.headShotMode = mode;
      this.Passupload = upload;
      this.PassApplicationid = this.pData.application_id.toString();
      this.PassFirstName = this.pData.firstName;
      if (this.$store.state.isHeadShotOpen == true) {
        this.$store.commit("setHeadshot", false);
      } else {
        this.$store.commit("setHeadshot", true);
      }
    },

    selectFileLetter(file) {
      this.currentFile = file;
    },

    btnvieweditor: function () {
      //  alert("dfdf");
      this.fullscreen1 = false;
      this.dialogeditor = true;
    },

    btnviewschedule: function () {
      //alert(this.$apiPathNew + '/assets/' +  this.$store.state.eventUrl + '/tnc/schedule.pdf');
      this.fullscreen1 = false;
      this.schedulepath =
        this.$apiPathNew +
        "/assets/" +
        this.$store.state.eventUrl +
        "/tnc/schedule.pdf";

      this.dialogpdf = true;
    },

    loadId(upload) {
      this.PassuploadFront = upload;
      this.PassApplicationidFront = this.pData.application_id.toString();
      this.PassFirstNameFront = this.pData.firstName;
      if (this.$store.state.isIdOpen == true) {
        this.$store.commit("setIdOpen", false);
      } else {
        this.$store.commit("setIdOpen", true);
      }
    },

    makefulldilog: function () {
      this.fullscreen1 = !this.fullscreen1;
      if (this.fullscreen1 == true) {
        this.maxiconname = "mdi-fullscreen-exit";
      } else {
        this.maxiconname = "mdi-fullscreen";
      }
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar.display = true;
      this.snackbar.text = stext;
      this.snackbar.color = scolor;
    },

    loadIdback(upload) {
      this.PassuploadBack = upload;
      this.PassApplicationidBack = this.pData.application_id.toString();
      this.PassFirstNameBack = this.pData.firstName;
      if (this.$store.state.isIdBackOpen == true) {
        this.$store.commit("setIdBackOpen", false);
      } else {
        this.$store.commit("setIdBackOpen", true);
      }
    },

    loadprofile: async function () {
      await this.$axios
        .get(
          "/media/details/" +
            this.$store.state.eventInfo.EventId +
            "/" +
            this.$store.state.eventInfo.AppId
        )
        .then((res) => {
          this.pData = res.data.result;
          console.log("Loaddata");
          console.log(this.pData);
          this.$store.commit(
            "setHeadshotImg",
            this.$apiPhotoPath + "/" + this.pData.photo
          );

          this.$store.commit(
            "setIdImg",
            this.$apiPhotoPath + "/" + this.pData.photoIdFront
          );

          this.$store.commit(
            "setIdBackImg",
            this.$apiPhotoPath + "/" + this.pData.photoIDBack
          );

          if (this.pData.status_named == "Amended") {
            this.btnEdit = true;
            this.Appstatus =
              " Application is Amend Due to " +
              this.pData.reason_name.toLowerCase();
          } else if (this.pData.status_named == "Rejected") {
            // this.btnEdit = true;
            this.Appstatus =
              " Application is Rejected Due to " +
              this.pData.reason_name.toLowerCase();
          } else if (this.pData.status_named == "Submitted") {
            this.btnEdit = true;
            this.Appstatus = "Pending for Approval";
          } else if (this.pData.status_named == "Printed") {
            this.btnEdit = false;
            this.Appstatus = "Your Application is Printed";
          } else {
            this.btnEdit = false;
            this.Appstatus = "Your application is Approved";
          }
        });
    },

    btnEditPersonaldetatl: function () {
      this.DilogBasic = true;
    },
    btncheckupload: function () {
      this.isfileupload = true;
    },

    btnupload: async function () {
      if (this.currentFile == "") {
        this.showSnackbar("error", "Please select File !");
        return;
      }
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      await this.$axios
        .post(
          "/Media/UploadFile/" + this.pData.application_id,
          formData
        )
        .then((res) => {
          this.showSnackbar("success", "File Uploaded!");
          this.currentFile = "";
          this.isfileupload = false;
          this.pData.editorLetter = res.data.result;
          this.isLoading = false;
        })
        .catch()
        .finally();
    },

    btnEditPersonaldetatlHide: function (abc) {
      this.DilogBasic = abc.open;
      if (abc.refresh == true) {
        this.loadprofile();
      }
    },

    btnEditPublicationDetals: function () {
      this.DilogPublication = true;
    },

    btnEditPublicationdetailHide: function (abc) {
      this.DilogPublication = abc.open;
      if (abc.refresh == true) {
        this.loadprofile();
      }
    },

    btnEditJobDetals: function () {
      this.DilogJob = true;
    },

    btnEditJobdetailHide: function (abc) {
      this.DilogJob = abc.open;
      if (abc.refresh == true) {
        this.loadprofile();
      }
    },

    btnEditAdditionalDetals: function () {
      this.DilogAdditional = true;
    },

    btnEditAdditionaldetailHide: function (abc) {
      this.DilogAdditional = abc.open;
      if (abc.refresh == true) {
        this.loadprofile();
      }
    },
  },
};
</script>

<style scoped>
.divHead {
  padding: 6px;
  border-left: 5px solid #1976d2;
  background-color: #eff8ff;
  text-transform: uppercase;
  height: 50px;
}
</style>