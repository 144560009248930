<template>
  <div class="mt-2" style="min-height:40px;">
    <div class="textF">{{ Text }}</div>
    <div class="valueF">{{ Value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    Text: String,
    Value: String,
  },
};
</script>
<style scoped>
.textF {
  color: rgb(94, 94, 94);
  font-size: 0.8rem;
  text-transform: capitalize;
  
}
.valueF {
  color: #000;
  font-size: 1rem;
  text-transform: capitalize;
}
</style>