<template>
  <v-dialog v-model="show" scrollable persistent max-width="1000px">
    <v-card>
      <v-card-title>PERSONAL DETAILS</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 1200px">
        <v-form ref="formStep1" v-model="formStep1">
          <div class="cardHeader">Personal Details</div>
          <v-card flat class="pa-4">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="First Name *"
                  :rules="[rules.req]"
                  v-model="appData.firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Last Name"
                  v-model="appData.lastName"
                ></v-text-field>
              </v-col>

 <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Father/Guardian Name"
                  v-model="appData.fatherName"
                ></v-text-field>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="appData.gender"
                  hide-details
                  outlined
                  dense
                  label="Gender *"
                  :items="['Male', 'Female', 'Others']"
                  :rules="[rules.req]"
                >
                </v-select
              ></v-col>
              <v-col cols="12" md="4">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="appData.dob"
                      outlined
                      dense
                      hide-details
                      label="DOB *"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="appData.dob"
                    :max="dob_max"
                    min="1950-01-01"
                    @change="savedob"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="appData.nationality"
                  hide-details
                  outlined
                  dense
                  label="Nationality *"
                  :items="nationality"
                  :rules="[rules.req]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="appData.idProofType"
                  hide-details
                  outlined
                  dense
                  label="ID Proof Type *"
                  :items="[
                  'Driving Licence',
                    'National ID',
                    'Passport',
                  ]"
                  :rules="[rules.req]"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="appData.idProofNumber"
                  hide-details
                  outlined
                  dense
                  label="ID Proof Number *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <div class="cardHeader">Address Details</div>
          <v-card flat class="pa-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Line 1 *"
                  :rules="[rules.req]"
                  v-model="appData.addLine1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Line 2"
                  v-model="appData.addLine2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="appData.city"
                  hide-details
                  outlined
                  dense
                  label="Town/City *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="appData.state"
                  hide-details
                  outlined
                  dense
                  label="State *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="appData.country"
                  hide-details
                  outlined
                  dense
                  label="Country *"
                  :items="countries"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="appData.zipCode"
                  hide-details
                  outlined
                  dense
                  label="Postal/Zip Code *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>

          <div class="cardHeader">Contact Details</div>
          <v-card flat class="pa-4">
            <v-row>
              <!-- <v-text-field
                    v-model="appData.mobile"
                    hide-details
                    outlined
                    dense
                    label="Mobile *"
                    :rules="[rules.req]"
                  ></v-text-field> -->

              <v-col cols="12" md="2">
                <v-select
                  v-model="appData.ext"
                  hide-details
                  outlined
                  dense
                  label="Country Code *"
                  :items="countriescode"
                  item-text="code"
                  item-value="dial_code"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="appData.mobile"
                  outlined
                  dense
                  maxlength="10"
                      label="Mobile *"
                      :rules="Vmobilenumber"
                      @keypress="filterNum(event)"
                ></v-text-field>
              </v-col>
              <!-- 
              <vue-tel-input-vuetify
                v-model="appData.mobile"
                outlined
                dense
                label="Mobile *"
                :rules="[rules.req]"
              ></vue-tel-input-vuetify> -->

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="appData.email"
                  hide-details
                  outlined
                  dense
                  label="Email Id *"
                  :rules="[rules.req, rules.email]"
                ></v-text-field>
              </v-col>



              <!-- <v-col cols="12" md="12">
              <div class="text-center">
                <v-btn
                  large
                  class="pl-5 pr-5"
                  :disabled="!formStep1"
                  color="success"
                  @click="save"
                  :loading="stepLoading"
                  >SAVE</v-btn
                >

                <v-btn
                  large
                  class="pl-5 pr-5 ma-2"
                  :disabled="!formStep1"
                  color="error"
                  @click="btncancel"
                  @close="show = false"
                  :loading="stepLoading"
                  >CANCEL</v-btn
                >
              </div>
            </v-col> -->
            </v-row>
          </v-card>
<!-- Comment due to Hockey FIH 2023 -->
  <!-- <div class="cardHeader">Covid-19 Vaccination Detail</div>
          <v-card flat class="pa-4">
            <v-row>
               <v-col cols="12" md="12">
                  <v-radio-group
                    class="mt-0"
                    v-model="appData.isVaccinated"
                    hide-details
                    label="Have you been vaccinated? *"
                    :rules="[rules.req]"
                  >
                     <v-radio label="First Dosage" value="First Dosage"></v-radio>
                    <v-radio label="Fully Vaccinated" value="Fully Vaccinated"></v-radio>
                    <v-radio label="Not Vaccinated" value="Not Vaccinated"></v-radio>
                  </v-radio-group>
                </v-col>
            </v-row>
          </v-card> -->
          

        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          large
          color="error"
          @click="btncancel"
          @close="show = false"
          :loading="stepLoading"
          ><v-icon left> mdi-close-thick </v-icon>CLOSE</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          large
          :disabled="!formStep1"
          color="success"
          @click="save"
          :loading="stepLoading"
          ><v-icon left> mdi-content-save </v-icon>SAVE</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-snackbar
      top
      centered
      v-model="snackbar.display"
      :color="snackbar.color"
      >{{ snackbar.text }}</v-snackbar
    >
    <v-overlay :value="isLoading" style="z-index: 9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
//import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";

import nationality from "../../../data/nationality.json";
import countrycode from "../../../data/countryCode.json";
import country from "../../../data/country.json";
export default {
  components: {},
  props: ["fData", "show"],

  //  props: {
  //     fData: {
  //       type: Object,
  //     },
  //     show: {
  //       type: Boolean,
  //     },
  //   },

  data: () => ({
    snackbar: { display: false, text: "", color: "primary" },
    isLoading: false,
    formStep1: false,
    dob_max: "",
    menu: false,
    stepLoading: false,
    countries: [],
    nationality: [],
    countriescode: [],
    appData: {
      application_id: "",
      event_id: "",
      firstName: "",
      lastName: "",
      gender: "",
      dob: "",
      nationality: "",
      idProofType: "",
      idProofNumber: "",
      passportExpiryDate:"",
      addLine1: "",
      addLine2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      mobile: "",
      email: "",
      ext: "",
      isVaccinated: "",
      fatherName:"",
    },
    rules: {
      req: (val) => (val !== null && val !== "") || "Required!",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
      reqArr: (val) => val.length > 0 || "Required!",
    },
     Vmobilenumber: [
      (v) => !!v || "Required",
      (v) =>
        (!isNaN(parseFloat(v)) && v >= 1 && v <= 99999999999999) ||
        "10 Number Only",
    ],
  }),

  mounted() {

     let d = new Date();
    d.setDate(d.getDate() - 6570);
    this.dob_max = d.toISOString().substr(0, 10);
    
    this.countries = country;
    this.countriescode = countrycode;
    this.nationality = nationality;
    //console.log(this.fData.firstName);
    this.binddata();
  },
  methods: {
    filterNum: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^\d+$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    savedob(date) {
      this.$refs.menu.save(date);
    },
    binddata: function () {
      this.appData.application_id = this.fData.application_id;
      this.appData.event_id = this.fData.event_id;
      this.appData.firstName = this.fData.firstName;
      this.appData.lastName = this.fData.lastName;
      this.appData.gender = this.fData.gender;
      this.appData.dob = this.fData.dob;
      this.appData.nationality = this.fData.nationality;
      this.appData.idProofType = this.fData.idProofType;
      this.appData.idProofNumber = this.fData.idProofNumber;
      this.appData.addLine1 = this.fData.addLine1;
      this.appData.addLine2 = this.fData.addLine2;
      this.appData.city = this.fData.city;
      this.appData.state = this.fData.state;
      this.appData.country = this.fData.country;
      this.appData.zipCode = this.fData.zipCode;
      this.appData.mobile = this.fData.mobile;
      this.appData.email = this.fData.email;
      this.appData.mobile = this.fData.mobile;
      this.appData.ext = this.fData.ext;
      this.appData.isVaccinated = this.fData.isVaccinated;
      this.appData.fatherName = this.fData.fatherName;

    },

    save: async function () {
      // console.log(this.countrycode);
      //this.appData.ext=this.countrycode.d;
      this.isLoading = true;
      // console.log(this.appData);
      await this.$axios
        .put("Media/applicationPersonal", this.appData)
        .then((res) => {
          //console.log(res.data);
          if (res.data.status == "Success") {
            this.$emit("hideBasicDilog", { open: false, refresh: true });
            this.showSnackbar("success", "Updated Successfully!");
          } else if (res.data.status == "Failed") {
            if (res.data.message == "ALREADYEXISTS") {
              this.showSnackbar("error", "Applicant already registered!");
            } else {
              this.showSnackbar("error", "Something went wrong!");
            }
          } else {
            this.showSnackbar("error", "Something went wrong!");
          }
          this.isLoading = false;
        });
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar.display = true;
      this.snackbar.text = stext;
      this.snackbar.color = scolor;
    },

    btncancel: function () {
      // this.$emit("hideBasicDilog", false);
      this.$emit("hideBasicDilog", { open: false, refresh: false });
      this.binddata();
    },
  },
};
</script>

<style>
</style>