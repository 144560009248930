<template>
  <div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn  outlined color="error" @click="OpenCamera">
        <v-icon left>mdi-camera</v-icon>
        Camera
      </v-btn>

      <v-btn  outlined color="error" @click="OpenFile"
        ><v-icon left>mdi-file</v-icon> File</v-btn
      >
      <v-spacer></v-spacer>
    </v-card-actions>

    <div v-if="mode == 'camera'">
      <v-dialog v-model="modelCamera" max-width="500" persistent>
        <v-card class="pa-5">
          <vue-web-cam
            ref="webcam"
            :selectFirstDevice="true"
            width="100%"
            height=""
          ></vue-web-cam>
          <v-card-actions>
            <v-btn outlined color="error" @click="closeCapturePic"
              >Cancel</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="success" @click="capturePic"
              ><v-icon left>mdi-camera</v-icon> Capture</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <input
        style="display: none"
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
      />
      <v-dialog v-model="modelPhoto" max-width="500" persistent>
        <v-card flat class="pa-5">
          <div class="content">
            <section class="cropper-area">
              <div class="img-cropper">
                <VueCropper
                  ref="cropper"
                  :aspect-ratio="22 / 24"
                  :src="imgSrc"
                  :key="imgSrc"
                  style="max-height: 400px"
                />
              </div>
            </section>
            <div class="pt-3 text-center">
              <div
                style="
                  border: 1px solid #e6e6e6;
                  background-color: #fafafa;
                  border-radius: 8px;
                  padding: 3px 0px;
                "
              >
                <v-btn icon small @click="imageOpt(0)" class="mr-3">
                  <v-icon>mdi-magnify-plus</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(1)" class="mr-3">
                  <v-icon>mdi-magnify-minus</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(2)" class="mr-3">
                  <v-icon>mdi-flip-horizontal</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(3)" class="mr-3">
                  <v-icon>mdi-rotate-right</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(4)" class="mr-3">
                  <v-icon>mdi-arrow-left-bold</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(5)" class="mr-3">
                  <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(6)" class="mr-3">
                  <v-icon>mdi-arrow-up-bold</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(7)" class="mr-3">
                  <v-icon>mdi-arrow-down-bold</v-icon>
                </v-btn>
                <v-btn icon small @click="imageOpt(8)">
                  <v-icon>mdi-lock-reset</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-card-actions class="pt-4 text-center">
            <v-btn color="error" outlined @click="close">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="closeDialog">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { WebCam } from "vue-web-cam";
export default {
  components: { VueCropper, "vue-web-cam": WebCam },
  props: {
    upload: String,
    Applicationid: String,
    FirstName: String,
  },
  data: () => ({
    imgSrc: "",
    mode:"",
    modelPhoto: false,
    modelCamera: false,
    photoupload: {
      event_id: 0,
      application_id: 0,
      firstName: "",
      photo: "",
      photoIdFront: "",
      photoIDBack: "",
    },
  }),

  mounted() {},
  methods: {
    OpenCamera: function () {
      this.mode = 'camera'
      this.modelCamera = true;
      this.$refs.webcam.start();
    },
    OpenFile: function () {
       this.mode = 'file'
      this.$refs.input.click();
    },
    setImage: function (e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (file.size > 1024 * 1024) {
        alert("File too big (> 1MB)");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          this.modelPhoto = true;
          // rebuild cropperjs with the updated source
          //this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    closeDialog1: async function () {
      this.$store.commit(
        "setHeadshotImg",
        this.$refs.cropper
          .getCroppedCanvas({
            width: 220,
            height: 240,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1)
      );

      if (this.upload == "server") {
        this.picpathbackup = this.$refs.cropper
          .getCroppedCanvas({
            width: 100,
            height: 120,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1);
        this.picpath = this.$refs.cropper
          .getCroppedCanvas({
            width: 100,
            height: 120,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1);
        this.photoupload.event_id = this.$store.state.eventInfo.EventId;
        this.photoupload.application_id = this.Applicationid;
        this.photoupload.firstName = this.FirstName;

        this.photoupload.photo = this.picpath.split(",")[1];

        await this.$axios
          .put("Media/UploadPic", this.photoupload, {
            headers: { Appkey: this.$aKey },
          })
          .then((res) => {
            console.log(this.photoupload);
            console.log(res.data.status);

            if (res.data.status == "Success") {
              this.showSnackbar("Success", "Photo Croped!");
              this.appData.picHeadShot = res.data.result;
            }
          });
      }
      this.$store.commit("setHeadshot", false);

      this.modelPhoto = false;
      
    },

    closeDialog: function () {
      this.$store.commit(
        "setHeadshotImg",
        this.$refs.cropper
          .getCroppedCanvas({
            width: 220,
            height: 240,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1)
      );

      if (this.upload == "server") {
        this.picpathbackup = this.$refs.cropper
          .getCroppedCanvas({
            width: 220,
            height: 240,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1);
        this.picpath = this.$refs.cropper
          .getCroppedCanvas({
            width: 220,
            height: 240,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", 1);
        this.photoupload.event_id = this.$store.state.eventInfo.EventId;
        this.photoupload.application_id = this.Applicationid;
        this.photoupload.firstName = this.FirstName;
        this.photoupload.photo = this.picpath.split(",")[1];
        axios
          .put(this.$apiPathNew + "/Media/UploadPic", this.photoupload, {
            headers: { Appkey: this.$aKey },
          })
          .then(() => {})
          .catch()
          .finally();
      }
      this.$store.commit("setHeadshot", false);
      this.modelPhoto=false;
    },

    close: function () {
      this.$store.commit("setHeadshot", false);
      this.modelPhoto = false;
    },
    capturePic: function () {
      this.imgSrc = this.$refs.webcam.capture();
      this.$refs.webcam.stop();
      this.modelCamera = false;
      this.mode = "file";
      this.modelPhoto = true;
    },
    closeCapturePic: function () {
      this.$store.commit("setHeadshot", false);
      this.$refs.webcam.stop();
      this.modelCamera = false;
    },
    imageOpt: function (bIndex) {
      switch (bIndex) {
        case 0:
          this.$refs.cropper.relativeZoom(0.2);
          break;
        case 1:
          this.$refs.cropper.relativeZoom(-0.2);
          break;
        case 2:
          // const dom = this.$refs.cropper;
          // let scale = dom.getAttribute("data-scale");
          // scale = scale ? -scale : -1;
          // this.$refs.cropper.scaleX(scale);
          // dom.setAttribute("data-scale", scale);
          break;
        case 3:
          this.$refs.cropper.rotate(90);
          break;
        case 4:
          this.$refs.cropper.move(-10, 0);
          break;
        case 5:
          this.$refs.cropper.move(10, 0);
          break;
        case 6:
          this.$refs.cropper.move(0, -10);
          break;
        case 7:
          this.$refs.cropper.move(0, 10);
          break;
        case 8:
          this.$refs.cropper.reset();
      }
    },
  },
};
</script>

<style>
</style>